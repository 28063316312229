<template>
  <div class="container">
    <div class="content">
      <div class="topbox">
        <img src="../../../assets/bannerweb.png" alt="">
      </div>
      <div class="boxitem">
        <div class="itemtitle">
          {{formdata.title}}
        </div>
        <div class="itemtis">
          <div class="stylesame">日期：{{formdata.data}}</div>
          <div class="stylesame">来源：{{formdata.form}}</div>
          <div class="stylesame">浏览次数：{{formdata.looknum}}</div>
        </div>
        <div class="itemcont" v-html="formdata.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      formdata: {
        title: '关于开展线上就业服务的通知',
        data: '2022-06-13',
        form: '就业办',
        looknum: '100',
        content: '<p><span style="color: rgb(97, 104, 124);">美团的使命是“帮大家吃得更好，生活更好”。作为一家生活服务电子商务平台，公司聚焦“Food +Platform”战略，以“吃”为核心，通过科技创新，和广大商户与各类合作伙伴一起，努力为消费者提供品质生活，推动生活服务业需求侧和供给侧数字化升级。</span></p ><p><span style="color: rgb(97, 104, 124);">2018年9月20日，美团正式在港交所挂牌上市。美团将始终坚持以客户为中心，不断加大在科技研发方面的投入，更好承担社会责任，更多创造社会价值，与广大合作伙伴一起发展共赢。</span></p ><p><span style="color: rgb(97, 104, 124);">Introduction to Meituan</span></p ><p><span style="color: rgb(97, 104, 124);">The mission of Meituan is “We help people eat better, live better ”. As China’s e-commerce platform for services, Meituan places its focus on the “Food+ Platform” strategy and always takes “eating” as the core of its businesses. Resorting to its advantages in scientific innovation, Meituan joins hands with a vast number of merchants and diverse partners to offer consumers quality services and accelerate the digital upgrade of the life service industry on both demand and supply sides.</span></p ><p><span style="color: rgb(97, 104, 124);">Officially listed on the Main Board of the Stock Exchange of Hong Kong Limited on September 20, 2018, Meituan has always centered on customers and kept increasing investment in scientific R&amp;D, thus better fulfilling its social responsibilities, creating more values for the society, and seeking win-win cooperation with all partners.</span></p >'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.container{
  min-height: 100vh;
  background-image: url(../../../assets/bgcolor.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  .content{
    padding: 15px;
    box-sizing: border-box;
    .topbox{
      width: 100%;
      height: 129px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .boxitem{
      margin-top: 15px;
      border-radius: 10px;
      background-color: #fff;
      padding: 20px 15px;
      .itemtitle{
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #333;
      }
      .itemtis{
        display: flex;
        width: 100%;
        height: 31px;
        line-height: 31px;
        padding: 0px 18px;
        background-color: #e5edf5;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 10px;
        .stylesame{
          color: #666666;
          font-size: 11px;
          margin-right: 21px;
          &:last-child{
            margin-right: 0px;
          }
        }
      }
      .itemcont{
        line-height: 22px;
        font-size: 14px;
        color: #666;
      }
    }
  }
}

</style>
